import React, { useState } from 'react'

import Matches from '@components/matches'
import Statistics from '@components/statistics'
import Layout from '@components/layout'
import SEO from '@components/seo'

const IndexPage = () => {
  const [loader, setLoader] = useState(true)
  const [matches, setMatches] = useState([])

  const getMatches = m => setMatches(m)
  const getLoader = l => setLoader(l)

  const title = 'Ottelut'

  return (
    <Layout>
      <SEO title={title} />
      <Matches title={title} getMatches={getMatches} getLoader={getLoader} />
      <Statistics matches={matches} isLoading={loader} />
    </Layout>
  )
}

export default IndexPage
